import React from "react";

import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo/seo";
import Form from "../sections/contact-us/Form";
import Hero from "../sections/contact-us/Hero";

const ContactUsPage = () => {
  return (
    <PageWrapper headerDark footerDark>
      <Seo title="Contact us" desc="Reach us, contact us, send us a message." />
      <Hero />
      <Form />
    </PageWrapper>
  );
};

export default ContactUsPage;
